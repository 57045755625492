
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Multiselect from "@vueform/multiselect";
import RevenueCode from "@/views/pages/Settings/Codes/RevenueCode.vue";
import Modifier from "@/views/pages/Settings/Codes/Modifier.vue";
import Icd10 from "@/views/pages/Settings/Codes/Icd10.vue";
import HCPCS from "@/views/pages/Settings/Codes/HCPCS.vue";
import OccurrenceCodes from "@/views/pages/Settings/Codes/OccurrenceCodes.vue";
import OccurrenceSpanCodes from "@/views/pages/Settings/Codes/OccurrenceSpanCodes.vue";
import ValueCodes from "@/views/pages/Settings/Codes/ValueCodes.vue";
import ConditionCodes from "@/views/pages/Settings/Codes/ConditionCodes.vue";
import { useRouter } from "vue-router";
import { getPanel } from "@/api/encounter.api";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}

export interface Data {
  selectedCode: any;
  codes: any;
  facilities: [];
  facility: any;
}
export default defineComponent({
  name: "Code Master",
  components: { RevenueCode, Modifier, Icd10, HCPCS, Multiselect, OccurrenceCodes, OccurrenceSpanCodes, ValueCodes, ConditionCodes },
  setup(props) {
    let organizationId = ref<string | null>("");

    const router = useRouter();
    let data = reactive<Data>({
      selectedCode: "revenueCode",
      codes: [
        { code: "revenueCode", title: "Revenue Code" },
        { code: "modifiers", title: "Modifiers" },
        { code: "icd10", title: "ICD 10" },
        { code: "hcpcs", title: "Service Codes" },
        { code: "occurrenceCodes", title: "Occurrence Codes" },
        { code: "occurrenceSpanCodes", title: "Occurrence Span Codes" },
        { code: "valueCodes", title: "Value Codes"},
        { code: "conditionCodes", title: "Condition Codes"},
      ],
      facilities: [],
      facility: null,
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      const panel = await getPanel();
      data.facilities = panel.facilities;
      setCurrentPageTitle("Code Master");
    });

    function selectCode(code) {
      data.selectedCode = code;
    }

    return {
      organizationId,
      selectCode,
      data,
    };
  },
});
